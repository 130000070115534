import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const PreviewCompatibleImage = ({ image, ...props }) => {
  const { alt = "" } = props;
  const { src, fluid, gatsbyImageData } = image;

  if (fluid || gatsbyImageData) {
    return <GatsbyImage image={gatsbyImageData || fluid} {...props} alt={alt} loading={"lazy"} />;
  }

  if (src) return <img src={src} {...props} alt={alt} />;

  return null;
};

export default PreviewCompatibleImage;
