import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Popup from "reactjs-popup";

const defaultBgColor = "#14213D";

const CardProject = ({ type = "vertical", cardData }) =>
  cardData.secretProject ? (
    <Popup
      trigger={
        <div
          className={`card card--case card--case--${type}`}
          style={{ backgroundColor: cardData.cardColor.hex || defaultBgColor }}
        >
          <CardProjectContent cardData={cardData} />
        </div>
      }
      modal
      closeOnDocumentClick
    >
      <div className="popupModal">
        {/*<div className="popupModal__header">NDA</div>*/}
        <div className="popupModal__content py-5">
          This project is under NDA, please <a href="https://adamfard.com/contact-us">contact us</a> to get more
          information.
        </div>
      </div>
    </Popup>
  ) : (
    <Link
      to={`/projects/${cardData.slug}`}
      className={`card card--case card--case--${type}`}
      style={{ backgroundColor: cardData.cardColor.hex || defaultBgColor }}
    >
      <CardProjectContent cardData={cardData} />
    </Link>
  );

const CardProjectContent = ({ cardData }) => {
    return (
        <>
            <div className="card-image">
                <GatsbyImage
                    image={cardData.cardImage.gatsbyImageData}
                    alt="card"
                    loading={"lazy"}/>
            </div>
            <div className="card-body text-light">
                <img src={cardData.logo.url} alt="Logo" loading="lazy"/>
                <p>{cardData.cardDescription}</p>
                <div className="d-flex align-items-center justify-content-center">
                    <span>{cardData.categoryProject}</span>
                    <div className="badge badge-primary ml-2">{cardData.industry}</div>
                </div>
            </div>
        </>
    );
};

CardProject.propTypes = {
  type: PropTypes.string,
  cardData: PropTypes.object.isRequired
};

export default CardProject;
