import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Image from "./Image";
import ViewCounter from "./ViewCounter";

const CardBlog = ({ type = "vertical", cardData }) => {
  return (
      <Link
          to={`/blog/${cardData.slug}`}
          className={`card card--common card--blog card--blog--${type}`}
      >
        <Image
            className="card-img-top"
            image={{gatsbyImageData: cardData.cardImage.gatsbyImageData, src: cardData.cardImage.url}}
            alt={cardData.title}
        />
        <div className="card-body">
          <div className="card--blog__parameters">
            <span>{cardData.category.name}</span>
            <div className="badge badge-light">
              <ViewCounter id={cardData.slug} isCard/> views
            </div>
          </div>
          <h3>{cardData.title}</h3>
          <p className="flex-grow-1 mb-0">{cardData.cardDescription}</p>
        </div>
      </Link>
  );
};

CardBlog.propTypes = {
  type: PropTypes.string,
  cardData: PropTypes.object.isRequired
};

export default CardBlog;
