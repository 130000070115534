import React, { useState, useEffect } from 'react';
import { FIREBASE_API_URL } from "../constants/common";

const ViewCounter = ({ id, isCard }) => {
  const [views, setViews] = useState('');

  useEffect(() => {
    const query = isCard ? `${FIREBASE_API_URL}/getViewCount?id=${id}&isCard=true` : `${FIREBASE_API_URL}/getViewCount?id=${id}`;
    fetch(query)
      .then(r => r.json())
      .then(data => setViews(data.views))
      .catch(error => console.error(error));
  }, [id]);

  return `${views || '...'}`;
};

export default ViewCounter;