import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { PopupButton } from "react-calendly"
// import Popup from "reactjs-popup";

import Layout from "../components/layout";
import CardProject from "../components/CardProject";
import CardBlog from "../components/CardBlog";
import ContactsBlockLandingPage from "../components/ContactsBlockLandingPage";
import imageWorkRemotely from "../images/photos/work-remotely.gif";
import imageWorkRemotelyWebp from "../images/photos/work-remotely.webp";

import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoHubspot from "../images/logos/brand/grey-hubspot.svg";
import LogoRedbull from "../images/logos/brand/grey-red-bull.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";

// import ImageGrow from "../images/photos/expertise.png";
// import ImageIndustries from "../images/photos/transforming_business_ux.png";
// import ImageOurProcess from "../images/photos/ux-design-process.jpg";
// import ImageBook from "../images/photos/The-Art-of-Launching-a-Digital-Product.png";
// import ImageHero from "../images/photos/ux-design-agency-3.jpg";
// import LogoClutch from "../images/logos/brand/Top_B2B_Western_Europe.png";
// import LogoGoodfirms from "../images/logos/brand/top-web-design-company.png";
// import LogoDAN from "../images/logos/brand/featured-ux-studio.png";

// import LogoIntercom from '../images/logos/brand/intercom.svg';
// import LogoSlack from '../images/logos/brand/slack.svg';
// import LogoSpotify from '../images/logos/brand/spotify.svg';
// import LogoTarget from '../images/logos/brand/target.svg';
// import LogoTime from '../images/logos/brand/time.svg';

import IconCheck from "../images/icons/interface/check.svg";
import IconCompass from "../images/icons/theme/tools/compass.svg";
import IconWoodHorse from "../images/icons/theme/home/wood-horse.svg";
import IconStar from "../images/icons/theme/general/star.svg";
import IconSmile from "../images/icons/theme/general/smile.svg";
import IconChartLine from "../images/icons/theme/shopping/chart-line-1.svg";

import Divider1 from "../images/dividers/divider-3.svg";
// import Divider2 from "../images/dividers/divider-2.svg";
// import Divider2 from '../images/dividers/divider-2.custom.svg';

import DecorationDots6 from "../images/decorations/deco-dots-6.svg";
import DecorationBlob6 from "../images/decorations/deco-blob-6.svg";
import DecorationLine1 from "../images/decorations/deco-lines-1.svg";
import DecorationLine6 from "../images/decorations/deco-lines-6.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import useInViewPort from "../hooks/useInViewPort";
import QuoteIcon from "../images/icons/theme/text/quote-1.svg";
import ThinkpricingReview from "../images/logos/thinkpricing-review.svg";
import QoloReview from "../images/logos/qolo-review.svg";
import AgentadvanceReview from "../images/logos/agentadvance-review.svg";
import Testimonials from "../components/Testimonials";
import { useMixpanelTracker } from "../hooks/useMixpanelTracker";
// import DecorationBlob19 from "../images/decorations/deco-blob-19.svg";

const UserExperienceDesignPage = ({ data }) => {
  const [isInViewRef, isInView] = useInViewPort();
  // useContactFormMixpanelTracker(".section-contacts-block", [isInView, isInViewProp]);
  const ThinkpricingAvatar = data.ThinkpricingAvatar.childImageSharp.gatsbyImageData;

/*   useMixpanelTracker("43", (mixpanel, formData) => {
    mixpanel.track("Exit intent form submission", {
      name: formData.name,
      email: formData.email
    });

    mixpanel.people.set({
      $name: formData["name"],
      $email: formData["email"],
    });
  }); */

  return (
    <Layout
      hideHeader
      hideFooter
      isMainPage
    >
      <div className="index-page">
        <SectionFirstScreen data={data} />
        <SectionTrustedCompanies />
        <SectionTopUXAgency data={data} />
        <SectionCardsProject data={data} />
        <TestimonialBlock data={data} />
        <SectionClutchReviews data={data} />
        {/* <SectionDesignTeams /> */}
        <SectionExpertise data={data} />
        {/* <SectionDigTransform data={data} /> */}
        <SectionIndustries />
        <SectionOurProcess data={data} />
        <div ref={isInViewRef}>
        </div>
      </div>
      <ContactsBlockLandingPage isInViewProp={isInView} />
    </Layout>
  );
};

const TestimonialBlock = ({ data }) => {
  const ThinkpricingAvatar = data.ThinkpricingAvatar.childImageSharp.gatsbyImageData;
  const QoloAvatar = data.QoloAvatar.childImageSharp.gatsbyImageData;
  const AgentadvanceAvatar = data.AgentadvanceAvatar.childImageSharp.gatsbyImageData;

  return (
    <Testimonials
      className="pt-3 pb-6 pb-md-0 layer-4"
      title="Hear from our clients"
      testimonials={[
        {
          content: `The final UX design meets all our requirements and looks great.
                                    The team is efficient and to the point, impressively able to understand design
                                    needs and integrate feedback.`,
          imageFixed: ThinkpricingAvatar,
          logo: (
            <ThinkpricingReview
              className="align-items-left"
              style={{ width: "178px" }}
            />
          )
        },
        {
          content: `The MVP received positive feedback from the internal team.
                                    Adam Fard UX Studio creates high-quality designs that meet the needs of the client.
                                    The team is hard-working, innovative, and driven.`,
          imageFixed: QoloAvatar,
          logo: (
            <QoloReview
              className="align-items-left"
              style={{ width: "148px" }}
            />
          )
        },
        {
          content: `Adam Fard UX Studio gave us the hands-on feeling of
                                    a small shop and the quality and professionalism of a big shop.`,
          imageFixed: AgentadvanceAvatar,
          logo: (
            <AgentadvanceReview
              className="align-items-left"
              style={{ width: "143px" }}
            />
          )
        }
      ]}
    />
  );
};

const SectionFirstScreen = ({ data }) => {
  const ImageHero = data.ImageHero.childImageSharp.gatsbyImageData;

  return (
    <section className="o-hidden pt-5 pt-md-2 pb-2">
      <div className="container">
        <div className="row align-items-center min-vh-40">
          <div className="col-lg-7 text-left mb-4 mb-lg-0">
            <h1 className="h1">
              We're a UI/UX Agentur & working with Berlin based Startups
              {/* <span className="text-primary-2"> </span> */}
            </h1>
            <div className="my-4 lead">
              <div className="mb-1 badge badge-primary-2">What we offer:</div>
              
              {/* <ul className="lead list-unstyled">
                <li className="mb-2"><strong>$114</strong> ROI for every $1 invested;</li>
                <li className="mb-2"><strong>57%</strong> increase of sales;</li>
                <li className="mb-2"><strong>300%</strong> reduced costs on development & customer support;</li>
              </ul> */}

              <ul className="list-unstyled">
              <li className="d-flex py-2 align-items-center">
                <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                  <IconCheck className="injected-svg icon bg-primary-3" />
                </div>
                <span className="lead">
                  <strong>Scale your Product</strong> through exceptional User Experience;
                </span>
                </li>
                <li className="d-flex py-2 align-items-center">
                <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                  <IconCheck className="injected-svg icon bg-primary-3" />
                </div>
                <span className="lead">
                  <strong>Deep understanding</strong>  of your target audience and market;
                </span>
                </li>
                <li className="d-flex py-2 align-items-center">
                <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                  <IconCheck className="injected-svg icon bg-primary-3" />
                </div>
                <span className="lead">
                  <strong>Iterative Design</strong> to minimize the feedback loop and stay agile;
                </span>
              </li>
              <li className="d-flex py-2 align-items-center">
                <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                  <IconCheck className="injected-svg icon bg-primary-3" />
                </div>
                <span className="lead">
                  <strong>UI Design</strong> that will achieve the "WOW effect";
                </span>
              </li>
              <li className="d-flex py-1 align-items-center">
                <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                  <IconCheck className="injected-svg icon bg-primary-3" />
                </div>
                <span className="lead">
                  <strong>Scalable Design System</strong> & clear documentation for your devs.
                </span>
              </li>
            </ul>
              {/* <p className="lead">
                We've made millions of users happy since 2016 and counting.
              </p> */}
            </div>
            {/* <Link to="/free-strategy-session" id="freeconsultation" className="btn btn-lg btn-primary">
                Free Strategy Session
              </Link> */}

            {/* <a
              href="/contact-us"
              id="freeconsultation"
              className="btn btn-lg btn-primary"
            >
              Check Our Process
            </a> */}

            <a id="freeconsultation"
              className="btn btn-lg btn-primary typeform-share button"
                // href="https://adamfard.typeform.com/to/O1PGBx"
                href="#contact-us"
                data-mode="popup"
                target="_blank">
              Request more information
              </a>
            
            {/* <PopupButton
              className="btn btn-lg btn-primary typeform-share button"
              color="#00a2ff"
              text="Schedule a Call"
              textColor="#ffffff"
              url="https://calendly.com/joe-user-research/30min"
              utm={{
                utmCampaign: 'Calendly Link Integration',
                utmMedium: 'Google Ad',
                utmSource: 'Landing Page',
              }}
            /> */}
        
              {/* <a 
                className="btn btn-lg btn-outlined text-primary ml-2 button"
                  href="https://adamfard.com/ux-design-process"
                  data-mode="popup"
                  target="_blank">
                    Our UX Process
              </a> */}
          </div>
          <div
            className="col-lg-5 text-center "
            style={{ position: "relative" }}
          >
              <GatsbyImage style={{display: "inline-block"}} objectFit="contain" alt={"ImageApp"} image={ImageHero} loading="eager" className="min-vw-50"/>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionTrustedCompanies = () => (
  <section className="pt-0 pb-2 pb-md-6">
    <div className="container">
      <div className="row text-center mb-3">
        <div className="col">
          <small className="">Trusted by companies like</small>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ul className="Companieslogo d-flex align-items-center justify-content-center list-unstyled">
            <li>
              <LogoSamsung className="" />
            </li>
            <li>
              <LogoTmobile className="" />
            </li>
            <li>
              <LogoHubspot className="" />
            </li>
            <li>
              <LogoRedbull className="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

const SectionTopUXAgency = ({ data }) => {
  const LogoClutch = data.LogoClutch.childImageSharp.gatsbyImageData;
  const LogoGoodfirms = data.LogoGoodfirms.childImageSharp.gatsbyImageData;
  const LogoDAN = data.LogoDAN.childImageSharp.gatsbyImageData;

  return (
    <section className="top-ux-agency pt-3">
      <div className="container">
        <div className="row text-center">
          <div className="col-12 col-md-8 text-left">
            <h2 className="display-4">Award-Winning Agentur</h2>
            <p className="lead">
              Throughout our years of experience as a design agency, we've
              earned quite a few of awards:
            </p>
          </div>
        </div>
        <div className="row mt-4 d-flex">
          <div className="col-12 col-md-4 col-lg-4">
            <a
              // href="https://clutch.co/profile/adam-fard-ux-studio"
              target="_blank"
              className="card card-body"
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={LogoClutch}
                  alt=""
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  height={160}
                  style={{ height: "160px" }}
                  loading={"lazy"} />
              </div>

              <p className="mb-0" style={{ color: "#17313B" }}>
                <strong>
                  Clutch <span style={{ color: "#ff3d2e" }}> ★★★★★</span>
                </strong>
              </p>
              <h4>Top UX Agency & B2B company</h4>
              <p className="lead">
                Clutch is an industry-standard data-driven B2B ranking web site
                for digital studios and UX agencies.
              </p>
            </a>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <a
              // href="https://www.goodfirms.co/company/adam-fard-studio/services"
              target="_blank"
              className="card card-body"
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={LogoGoodfirms}
                  alt=""
                  style={{ height: "160px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"} />
              </div>
              <p className="mb-0" style={{ color: "#4385F4" }}>
                <strong>
                  GoodFirms <span style={{ color: "#ffba00" }}> ★★★★★</span>
                </strong>
              </p>
              <h4>Top App Design Company</h4>
              <p className="lead">
                GoodFirms is a review website that ranks top software and UX design
                agencies.
              </p>
            </a>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <a
              // href="https://digitalagencynetwork.com/agencies/berlin/"
              target="_blank"
              className="card card-body"
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={LogoDAN}
                  alt=""
                  style={{ height: "160px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"} />
              </div>
              <p className="mb-0" style={{ color: "#F3188D" }}>
                <strong>Digital Agency Network</strong>
              </p>
              <h4>Featured UX Agency</h4>
              <p className="lead">
                Digital Agency Network is an all-in-one platform to discover the
                best digital design and marketing agencies in the world.
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionCardsProject = ({ data }) => {
  const ImageAI = data.ImageAI.childImageSharp.gatsbyImageData;
  const ImageEdtech = data.ImageEdtech.childImageSharp.gatsbyImageData;
  const ImageSecurity = data.ImageSecurity.childImageSharp.gatsbyImageData;
  
  return (
    <section className="has-divider layer-4">
      <div className="decoration-wrapper d-none d-lg-block">
        <div
          className="decoration scale-1 top"
          style={{ right: "20%", transform: "matrix(-1, 0, 0, 1, 0, 0)" }}
        >
          <DecorationDots6 className="bg-yellow" />
        </div>
      </div>

      <div className="container py-0 ">
        <div className="row">
          <div className="col-12 mb-0 text-left">
            <h2 className="display-4">Featured projects</h2>
          </div>

          <div className="container mt-n6">
            <div className="row align-items-center">

              <div className="col-md-6 mb-4 pr-md-4">
                <div className="min-vw-40 photo-landing-page">
                  <GatsbyImage image={ImageAI} alt="Industries" className="col-md-12" loading={"lazy"} />
                </div>
              </div>

              <div className="col-md-6 mb-4">
                <h5 className="text-primary-2" style={{color:"#f97037"}}>th!nkpricing</h5>
                <h2 className="h1 mb-4">
                  AI Sales intelligence tool
              </h2>
                <p className="lead">
                  The Th!nkpricing app is a SaaS solution that boosts revenue by optimizing the service
                  or product price based on demand and supply. The app is backed by data science and AI which
                  is perfect for Sports, Cinema, Entertainment, and Ski Resorts.
              </p>

                <ul className="list-unstyled">
                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>Increased users' trust, in the platform's AI;</strong>
                    </span>
                  </li>

                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>Able to retain current users</strong>, which resulted in an increase of the marketing budget
                    to acquire new customers and confidently scale;
                  </span>
                  </li>

                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>
                        Clear design process and team facilitation;
                    </strong>
                    </span>
                  </li>
                </ul>

                <a
                  id="freeconsultation"
                  href="#contact-us"
                  className="lead mt-2 mb-2 mb-md-0 ml-4 ml-sm-4 hover-arrow"
                >
                  Tell Us About Your Project
                </a>

               
              </div>
            </div>

            <div className="row align-items-center pt-6">
              <div className="col-md-6 order-last order-md-first mb-4">
                <h5 className="text-blue" style={{color:"#36bfff"}}>Kinteract</h5>
                <h2 className="h1 mb-4">
                  Multiplatform Learning Management System
                </h2>
                <p className="lead">
                  An edTech platform for facilitating communication among students, parents, and teachers.
                </p>

                <ul className="list-unstyled">
                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>78% of Increase in the conversion rate</strong> Thanks to better usability, the schools are a lot more likely to
                      upgrade their trial accounts and become paying customers.
                    </span>
                  </li>

                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>Acquisition of new clients</strong> Based on new tailored features and interactive prototypes we helped acquire big clients:
                      UK Government, Sharjah Ministry of Education, Deloitte, etc.)
                  </span>
                  </li>

                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>Reduced costs by 3x</strong> Increased developers efficiency and reduced costs by having a user-centered design approach.
                    </span>
                  </li>
                </ul>
              
                <a
                  id="freeconsultation"
                  href="#contact-us"
                  className="lead mt-2 mb-2 mb-md-0 ml-4 ml-sm-4 hover-arrow"
                >
                  Tell Us About Your Project
                </a>
              </div>

              <div className="col-md-6 mb-4 pl-md-4">
                <div className="min-vw-40">
                  <GatsbyImage image={ImageEdtech} alt="Industries" className="col-md-12" loading={"lazy"} />
                </div>
              </div>
              
            </div>

            
            <div className="row align-items-center pt-6">

              <div className="col-md-6 mb-4 pr-md-4">
                <div className="min-vw-40 photo-landing-page">
                  <GatsbyImage image={ImageSecurity} alt="Industries" className="col-md-12" loading={"lazy"}/>
                </div>
              </div>


              <div className="col-md-6 mb-4">
                <h5 className="text-danger">MESH</h5>
                <h2 className="h1 mb-4">
                 Email Security Platform
                </h2>
                <p className="lead">
                  Throughout the project we ran user interviews and usability testing using a wide range of
                  design fidelity and incorporated all the findings into the designs.
                </p>

                <ul className="list-unstyled">
                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>Better understanding of users and their behavior;</strong>
                    </span>
                  </li>

                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>Improved user flow and navigation</strong> based on the UX research;
                  </span>
                  </li>

                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      <strong>Implementation-ready designs</strong> that aesthetically convey the sense of security.
                    </span>
                  </li>
                </ul>
              
                <a
                  id="freeconsultation"
                  href="#contact-us"
                  className="lead mt-2 mb-2 mb-md-0 ml-4 ml-sm-4 hover-arrow"
                >
                  Tell Us About Your Project
                </a>
              </div>
              
            </div>
        
          </div>

      
        </div>
      </div>
    </section>
  );
};

const SectionDesignTeams = () => (
  <section className="bg-primary-alt has-divider section-capabilities">
    <div className="divider bg-white">
      <Divider1 className="bg-primary-alt" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 mt-6 mb-4">
          <h2 className="display-4">Work by your own rules</h2>
          <p className="lead col-lg-8 pl-0">
            Our UX design agency creates solutions to address{" "}
            <strong>product usability, </strong>
            dwindling <strong>conversion rates</strong> and the ever-present
            challenges of <strong>user-retention & engagement</strong>.
          </p>
          <p className="lead col-lg-8 pl-0 mt-4">
            No matter whether you're set on your goal or need guidance, our
            agile UX Agentur is here to help. We are flexible enough to
            seamlessly integrate in your team’s processes.
          </p>
        </div>

        <div className="col-12 col-md-6 col-lg-6">
          <div className="card card--no-hover card--common card--blog--vertical card-body justify-content-between">
            <div className="d-flex align-items-center mb-4">
              <div>
                <IconCompass className="icon icon-lg align-items-left" />
              </div>
              <h4 className="ml-2">Dedicated Design Team</h4>
            </div>

            <p className="lead mb-4">
              Hire a design team and manage them the same way you do with
              in-house employess.
            </p>
            <h5>Works best for companies that:</h5>
            <ul className="lead mb-6">
              <li>Have a long-term project in mind</li>
              <li>Want to be able to rapidly scale the team up or down</li>
              <li>Don’t have the local talent that fits their needs</li>
            </ul>

            {/* <a
              href="#contact-us"
              id="freeconsultation"
              className="btn btn-lg btn-primary"
            >
              Contact US
            </a> */}

            <PopupButton
              className="btn btn-lg btn-primary"
              color="#00a2ff"
              text="Schedule a Call"
              textColor="#ffffff"
              url="https://calendly.com/joe-user-research/30min"
              utm={{
                utmCampaign: 'Calendly Link Integration',
                utmMedium: 'Google Ad',
                utmSource: 'Landing Page',
              }}
            />

          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-6">
          <div
            href="contact-us"
            className="card card--no-hover card--common card--blog--vertical card-body justify-content-between"
          >
            <div className="d-flex align-items-center mb-4">
              <div>
                <IconWoodHorse className="icon icon-lg align-items-left flip-x" />
              </div>
              <h4 className="ml-2">Managed Project from A to Z</h4>
            </div>

            <p className="lead mb-4">
              Hire a team of professionals who will get your design job done on
              time and on budget.
            </p>
            <h5>Works best for companies that:</h5>
            <ul className="lead mb-6">
              <li>Have a clear vision of the end-goal</li>
              <li>Don’t want to worry about infrastructure</li>
              <li>
                Want to have the designers manage themselves with little
                direction
              </li>
            </ul>

            {/* <a
              href="#contact-us"
              id="freeconsultation"
              className="btn btn-lg btn-primary"
            >
              Contact Us
            </a> */}

            <PopupButton
              className="btn btn-lg btn-primary"
              color="#00a2ff"
              text="Schedule a Call"
              textColor="#ffffff"
              url="https://calendly.com/joe-user-research/30min"
              utm={{
                utmCampaign: 'Calendly Link Integration',
                utmMedium: 'Google Ad',
                utmSource: 'Landing Page',
              }}
            />
            
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionExpertise = ({ data }) => {
  const ImageGrow = data.ImageGrow.childImageSharp.gatsbyImageData;

  return (
    <section className="bg-primary-alt has-divider section-capabilities">
      <div className="divider bg-white">
        <Divider1 className="bg-primary-alt" />
      </div>
    {/* <section className="bg-primary-alt pt-0 pt-md-5 pt-xl-7"> */}
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-md-last mb-4 mb-md-0">
            <GatsbyImage image={ImageGrow} alt="ImageGrow" className="" loading={"lazy"}/>
          </div>

          <div className="col-md-6 d-flex align-items-center">
            <div>
              <h2 className="display-4">Our expertises</h2>
              <p className="lead mb-4">
                We design digital products from sketches and ideas all the way to a coding-ready polished design.
                After launch, we help ensure the user base is happy and growing through iterative improvements.
              </p>

              <h4 className="mb-3">User Research</h4>
              <h4 className="mb-3">Usability Testing & Audit</h4>
              <h4 className="mb-3">UX Strategy</h4>
              <h4 className="mb-3">User Experience Design</h4>
              <h4 className="mb-3">User Interface Design</h4>

              <a
                href="#contact-us"
                id="freeconsultation"
                className="btn btn-lg btn-primary"
              >
                Get a Free UX Session
              </a>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const SectionDigTransform = ({ data }) => {
  const ImageIndustries = data.ImageIndustries.childImageSharp.gatsbyImageData;

  return (
    <section className="bg-primary-alt pt-0 pt-md-5 pt-xl-7 layer-4">
      <div className="decoration-wrapper d-none d-lg-block">
        <div
          className="decoration scale-1 top"
          style={{ right: "20%", transform: "matrix(-1, 0, 0, 1, 0, 0)" }}
        >
          <DecorationDots6 className="bg-yellow" />
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 mb-4">
            <GatsbyImage
              image={ImageIndustries}
              alt="Industries"
              className="col-md-11"
              loading={"lazy"} />
          </div>
          <div className="col-md-6 mb-4">
            <h2 className="h1 mb-4">
              Our UX agency digitally transforms businesses through design
            </h2>
            {/*<p className="lead">
                We have revolutionised the performance of multiple successful apps,
                including:
              </p>*/}

            <ul className="list-unstyled">
              <li className="d-flex py-2">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>Doubling in-app purchase rates</strong> for a Global
                  500 company’s app, by developing a stronger grasp of user
                  behavior and reducing its cognitive load
                </span>
              </li>
              <li className="d-flex py-2">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>Overhauling an app to drive downloads</strong> beyond
                  the 10M mark (and counting) while securing a 4.7+ average user
                  rating
                </span>
              </li>
              <li className="d-flex py-2">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>
                    Conception and implementation of Augmented Reality
                  </strong>{" "}
                  Experiences to boost net promoter scores and brand awareness,
                  making a bigger market impact
                </span>
              </li>
            </ul>
            <Link
              to="/contact-us"
              className="lead mt-2 mb-2 mb-md-0 ml-4 ml-sm-4 hover-arrow"
            >
              Tell us about your business goals
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="divider bg-primary-alt">
          <Divider1 className="" />
        </div> */}
    </section>
  );
};

const IndustriesTabs = [
  {
    name: "Finance",
    title: "Creating a simpler FinTech platform by embracing innovative design",
    caption:
      "We’ve designed, adapted and enhanced data visualization, e-wallets, online trading apps, credit scoring tools and more",
  },
  {
    name: "SaaS",
    title: "Achieving maximum user-engagement and satisfaction",
    caption:
      "SaaS (Software as a Service) is a thriving industry. Subscription-based models offer customers a simple, hassle-free service — but good UX design is crucial to maximize satisfaction. We’ve helped SaaS companies on all scales, from startups to established brands.",
  },
  {
    name: "Education",
    title: "Taking online education tools to the next level",
    caption:
      "EdTech is one of the most important industries in the digital age. We help educators deliver cutting-edge courses and resources with a market-leading user experience",
  },
  {
    name: "IT & Communication",
    title: "Making IT & communications simpler and more accessible",
    caption:
      "IT and communication platforms can be complex — or downright confusing — for professionals at all levels. Our work in this industry centers on creating smoother, simpler UX design for a more engaging experience.",
  },
  {
    name: "Healthcare & Wellness",
    title: "Bringing crucial knowledge and assistance to users",
    caption:
      "Everyone deserves access to informative healthcare & wellness resources, but poor UX design can leave people without the answers they need. We’ve transformed apps and online platforms for a stronger, more fulfilling experience.",
  }
];

class SectionIndustries extends Component {
  state = {
    activeTab: "Finance"
  };

  setNewActiveTab = tabName => {
    this.setState({
      activeTab: tabName
    });
  };

  render() {
    const { activeTab } = this.state;

    return (
      <section className="bg-primary-3 position-relative text-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="text-center col-md-12 col-lg-10 col-xl-9">
              <span className="badge badge-primary-2 mb-2">Industries</span>
              <div className="d-flex mb-5 justify-content-center">
                <ul className="nav custom-tabs custom-tabs--scroll lead">
                  {IndustriesTabs.map(({ name }, index) => (
                    <li
                      className="nav-item"
                      key={index}
                      onClick={() => this.setNewActiveTab(name)}
                    >
                      <span
                        className={`nav-link pl-0 ${
                          activeTab === name ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        {name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              {IndustriesTabs.map((info, index) => (
                <div
                  key={index}
                  className={`tab-animation ${
                    info.name === activeTab ? "active animated fadeIn" : ""
                  }`}
                >
                  <h3 className="h1">{info.title}</h3>
                  <p className="lead my-4">{info.caption}</p>
                  <a
                    id="freeconsultation"
                    href="#contact-us"
                    className="lead mt-2 mb-2 mb-md-0 hover-arrow text-primary-2"
                  >
                    Tell Us About Your Project
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="divider">
          <Divider2 />
        </div> */}
      </section>
    );
  };
};

const SectionClutchReviews = ({ data }) => {
  return (
    <section className="d-none d-md-block position-relative pt-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="card card-body card--no-hover">
              <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script>
              <div class="w-100 clutch-widget" data-url="https://widget.clutch.co" data-widget-type="4" data-expandifr="true" data-height="auto" data-clutchcompany-id="975724">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionOurProcess = ({ data }) => {
  const ImageOurProcess = data.ImageOurProcess.childImageSharp.gatsbyImageData;

  return (
    <section className="has-divider o-hidden pt-4 pt-md-0">
      <div className="decoration-wrapper d-none d-lg-block">
        <div className="decoration scale-1" style={{ top: 160, right: "12%" }}>
          <DecorationLine6 className="bg-primary" />
        </div>
      </div>

      <div className="divider">
        {/* <Divider1 className="bg-primary-alt" /> */}
      </div>

      <div className="container pt-0 pt-md-5 pt-xl-7">
        <div className="row justify-content-center text-center mb-2 mb-md-6">
          <div className="col-xl-8 col-lg-9 mt-5">
            <h2 className="display-4 mx-xl-5">Our UX design process</h2>
            {/*<p className="lead">Introduction - Process overview</p>*/}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 order-1 order-md-0">
            <div>
              <div className="process">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5>Research & Understanding</h5>
                  <p>
                    We gather inputs to grasp your business goals, product
                    market and users’ pain points.
                  </p>
                </div>
              </div>
              <div className="process">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5>Defining & Conception</h5>
                  <p>
                    We define a UX strategy and potential UX concepts. We’ll
                    make the perfect solution for your users.
                  </p>
                </div>
              </div>
              <div className="process">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5>Creation & Testing</h5>
                  <p>
                    We build user-interface wireframes and high-fidelity
                    prototypes. We test them to validate our solutions.
                  </p>
                </div>
              </div>
              <div className="process">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5>Handoffs & Analysis</h5>
                  <p>
                    We document all approved designs for developers and measure
                    their success. Your satisfaction is our priority.
                  </p>
                </div>
              </div>
            </div>
            {/* <Link
              to="/contact-us"
              className="lead mt-2 mb-2 mb-md-0 ml-4 ml-sm-6 hover-arrow"
            >
              Contact Us
            </Link> */}
            {/* <a href="#contact-us" className="lead mt-2 mb-2 mb-md-0 ml-4 ml-sm-6 hover-arrow">
                Contact Us to Get Started
              </a> */}
             <a
              href="#contact-us"
              id="freeconsultation"
              className="btn btn-lg btn-primary"
            >
              Get a Free UX Session
            </a>
          </div>

          <div className="col-md-6 d-flex align-items-center order-0 order-md-1 mb-4 mb-md-0">
            <picture>
                <source type="image/webp" srcSet={imageWorkRemotelyWebp}/>
                <source srcSet={imageWorkRemotely} type="image/gif"/>
                <img src={imageWorkRemotely} alt="" loading="lazy"/>
            </picture>

            {/* <Img
              fluid={ImageOurProcess}
              alt="Our Process"
              style={{ flexGrow: 1 }}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};
const SectionOurBook = ({ data }) => {
  const ImageBook = data.ImageBook.childImageSharp.gatsbyImageData;

  return (
    <section className="bg-primary text-light py-2 py-md-1 o-hidden">
      <div className="decoration-wrapper d-none d-lg-block">
        <div
          className="decoration scale-1"
          style={{ top: -30, left: "2%", transform: "rotate(-74.43deg)" }}
        >
          <DecorationBlob6 className="bg-yellow" />
        </div>
      </div>

      <div className="container align-items-center text-center text-md-left">
        <div className="row py-6 align-items-center">
          <div className="col-12 col-md-6 text-center pb-4 pb-md-0">
            <GatsbyImage
              image={ImageBook}
              alt="The Art of Launching a Product For Startups Cover"
              className="" loading={"lazy"}/>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <div>
              <span className="badge badge-primary-2 mb-2 lead">
                Handbook for Startups & Product Owners
              </span>
              <h2 className="h1">The Art of Launching a Product</h2>
              <p className="lead">
                Learn step-by-step, how to perfectly plan and execute the
                launch of your digital product.
              </p>
              <a
                href=" https://adamfard.com/download-ux-resources/launch-digital-product"
                className="btn btn-lg btn-white mt-3"
              >
                Download Free Book
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const SectionBlog = ({ FeaturedArticlesData }) => (
  <section className="bg-primary-alt pb-0 pb-md-5 pb-xl-7">
    <div className="decoration-wrapper d-none d-lg-block">
      <div
        className="decoration scale-1"
        style={{ top: -120, right: "15%", transform: "rotate(55.54deg)" }}
      >
        <DecorationLine1 className="bg-yellow" />
      </div>
    </div>

    <div className="container pb-6">
      <div className="row">
        <div className="col-12 row align-items-center mb-4">
          <div className="col-md-8">
            <h2 className="display-4">UX Blogs and News</h2>
          </div>
          <div className="col-md-4 text-md-right">
            <Link to="/blog" className="lead hover-arrow">
              Discover All UX Blogs
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);


export default UserExperienceDesignPage;

export const query = graphql`query UserExperienceDesignQuery {
  ImageGrow: file(relativePath: {eq: "photos/team-photo.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ImageIndustries: file(relativePath: {eq: "photos/transforming_business_ux.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ImageOurProcess: file(relativePath: {eq: "photos/ux-design-process.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ImageBook: file(
    relativePath: {eq: "photos/The-Art-of-Launching-a-Digital-Product.png"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ImageHero: file(
    relativePath: {eq: "photos/financial-app-design-landing-page-2.png"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  ImageEdtech: file(relativePath: {eq: "photos/edtech-product-design.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ImageSecurity: file(relativePath: {eq: "photos/mesh-project-2.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ImageAI: file(relativePath: {eq: "photos/tp-ai.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  LogoClutch: file(relativePath: {eq: "logos/brand/Top_B2B_Western_Europe.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  LogoGoodfirms: file(
    relativePath: {eq: "logos/brand/mobile-app-design-companies.png"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  LogoDAN: file(relativePath: {eq: "logos/brand/featured-ux-studio.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ThinkpricingAvatar: file(relativePath: {eq: "photos/think-avatar.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 100, height: 100, layout: FIXED)
    }
  }
  QoloAvatar: file(relativePath: {eq: "photos/qolo-avatar.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 100, height: 100, layout: FIXED)
    }
  }
  AgentadvanceAvatar: file(relativePath: {eq: "photos/agent-avatar.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 100, height: 100, layout: FIXED)
    }
  }
}
`;
